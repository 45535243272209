import React, { useEffect } from "react";

const ContactForm = () => {
	useEffect(() => {
		var ifr = document.getElementById("JotFormIFrame-211301402104026");

		if (ifr) {
			var src = ifr.src;
			var iframeParams = [];
			if (window.location.href && window.location.href.indexOf("?") > -1) {
				iframeParams = iframeParams.concat(
					window.location.href
						.substr(window.location.href.indexOf("?") + 1)
						.split("&")
				);
			}
			if (src && src.indexOf("?") > -1) {
				iframeParams = iframeParams.concat(
					src.substr(src.indexOf("?") + 1).split("&")
				);
				src = src.substr(0, src.indexOf("?"));
			}
			iframeParams.push("isIframeEmbed=1");
			ifr.src = src + "?" + iframeParams.join("&");
		}

		window.isPermitted = function (originUrl, whitelisted_domains) {
			var url = document.createElement("a");
			url.href = originUrl;
			var hostname = url.hostname;
			var result = false;
			if (typeof hostname !== "undefined") {
				whitelisted_domains.forEach(function (element) {
					if (
						hostname.slice(-1 * element.length - 1) === ".".concat(element) ||
						hostname === element
					) {
						result = true;
					}
				});
				return result;
			}
		};
	}, []);

	return (
		<iframe
			id="JotFormIFrame-211301402104026"
			title="Contact"
			allowtransparency="true"
			allowfullscreen="true"
			src="https://form.jotform.com/211301402104026"
			frameborder="0"
			style="
      min-width: 100%;
      height:940px;
      border:none;"
			scrolling="no"
		></iframe>
	);
};

export default ContactForm;
